import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import "../utils/global.css"
import moon from "../../content/assets/moon.png"
import sun from "../../content/assets/sun.png"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(0.75),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            ...scale(0.5),
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div
        style={{
          color: "var(--textNormal)",
          transition: "color 0.2s ease-out, background 0.2s ease-out",
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header
          style={{
            display: `flex`,
            justifyContent: `space-between`,
          }}
        >
          {header}
          <ThemeToggler>
            {({ theme, toggleTheme }) => (
              <label class="switch">
                <input
                  type="checkbox"
                  onChange={e =>
                    toggleTheme(e.target.checked ? "dark" : "light")
                  }
                  checked={theme === "dark"}
                />
                <span class="slider round">
                  <img
                    src={moon}
                    width="16"
                    height="16"
                    alt="dark mode"
                    style={{
                      pointerEvents: "none",
                      paddingTop: "3px",
                      paddingLeft: "2px",
                    }}
                  />{" "}
                  <img
                    src={sun}
                    width="16"
                    height="16"
                    alt="light mode"
                    style={{ pointerEvents: "none", paddingTop: "3px" }}
                  />
                </span>
              </label>
            )}
          </ThemeToggler>
        </header>
        <main>{children}</main>
        <footer></footer>
      </div>
    )
  }
}

export default Layout
